import React from "react"
import PropTypes from "prop-types"

const Background = ({children, color}) => {
  color = color === undefined ? "white" : color

  return (
    <div className={`tw-bg-${color} tw-w-screen`}>
      {children}
    </div>
  )
}

const Container = ({ children, maxWidth }) => {
  maxWidth = maxWidth === undefined ? "7xl" : maxWidth

  return (
    <div className={`tw-max-w-${maxWidth} tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-overflow-hidden`}>
      {children}
    </div>
  )
}

Background.propTypes = {
  children: PropTypes.node.isRequired,
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  Background,
  Container
}
