import React from "react"
import Header from "./Header";
import { Container } from "./Container"
import Footer from "./Footer";

export default function Layout({ isHomePage, children }) {
  return (
      <>
        <Header isHomePage={isHomePage} />
        <Container hideOverflow>
          <main className="px-3 px-md-5">
            { children }
          </main>
        </Container>
        <Footer/>
      </>
  )
}
