import React from "react"
import Layout from "./src/components/Layout"

// Import all styles (including Bootstrap)
import "./src/styles/global.scss"

// Import all js dependencies.
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min.js'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
