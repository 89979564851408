import React from "react"
import { Link } from "gatsby"
import { siteMetadata } from "../../gatsby-config"
import { StaticImage } from "gatsby-plugin-image";
import { Container } from "./Container"

const Header = ({ location }) => {
  return (
    <header className="bg-light sticky-top" id="top">
      <Container>
        <nav className="navbar navbar-expand-md navbar-light bg-light">
          <Link className="navbar-brand" to="/#top">
            <StaticImage
              src="./../../static/images/label-ville-europeenne.png"
              height={50}
              loading="eager"
              placeholder="none"
              className="d-inline-block align-top"
              alt={siteMetadata.title}
            />
          </Link>
        </nav>
      </Container>
    </header>
  )
}

export default Header
